import axios from "axios";
import { actionStart, actionSuccess, actionFailed } from "../redux-store/ActionSlice";


export const generateApiKey = async (dispatch) => {
    dispatch(actionStart())
    try {
      const resp = await axios.post(process.env.REACT_APP_BASE_URL + "/client/generate/key", {},{
        headers: {
          'accept': 'application/json',
          'content-type': 'application/json',
          'Authorization': 'Bearer ' + localStorage.getItem('token')
        }
      })
      dispatch(actionSuccess())
      return resp
    } catch (err) {
      dispatch(actionFailed())
      return err
    }
  }


  export const udapteCallbackUrl = async (dispatch,url) => {
    dispatch(actionStart())
    try {
      const resp = await axios.put(process.env.REACT_APP_BASE_URL + "/webhook", url,{
        headers: {
          'accept': 'application/json',
          'content-type': 'application/json',
          'Authorization': 'Bearer ' + localStorage.getItem('token')
        }
      })
      dispatch(actionSuccess())
      return resp
    } catch (err) {
      dispatch(actionFailed())
      return err
    }
  }
  

  export const statusCallbackUrl = async (dispatch,url) => {
    dispatch(actionStart())
    try {
      const resp = await axios.put(process.env.REACT_APP_BASE_URL + "/webhook/status", url,{
        headers: {
          'accept': 'application/json',
          'content-type': 'application/json',
          'Authorization': 'Bearer ' + localStorage.getItem('token')
        }
      })
      dispatch(actionSuccess())
      return resp
    } catch (err) {
      dispatch(actionFailed())
      return err
    }
  }


  export const getPhoneNumbers = async (dispatch) => {
    dispatch(actionStart())
    try {
      const resp = await axios.get(process.env.REACT_APP_BASE_URL + "/phonenumber",{
        headers: {
          'accept': 'application/json',
          'content-type': 'application/json',
          'Authorization': 'Bearer ' + localStorage.getItem('token')
        }
      })
      dispatch(actionSuccess())
      return resp
    } catch (err) {
      dispatch(actionFailed())
      return err
    }
  }


  export const updatePhoneNumberCallback = async (dispatch, callback) => {
    dispatch(actionStart())
    try {
      const resp = await axios.post(process.env.REACT_APP_BASE_URL + "/phonenumber/callback", callback, {
        headers: {
          'accept': 'application/json',
          'content-type': 'application/json',
          'Authorization': 'Bearer ' + localStorage.getItem('token')
        }
      })
      dispatch(actionSuccess())
      return resp
    } catch (err) {
      dispatch(actionFailed())
      return err
    }
  }